<template>
  <v-data-table
    :headers="headers"
    :items="getData"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-toolbar-title class="uppercase">Tags</v-toolbar-title>

        <v-spacer></v-spacer>

        <v-dialog v-model="dialog" max-width="500px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="secondary" text class="mb-2" v-bind="attrs" v-on="on">
              <v-icon>add</v-icon>Nuovo Tag
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="headline">{{ formTitle }}</span>
            </v-card-title>

            <v-card-text>
              <div class="container w-full flex flex-col">
                <v-text-field v-model="editedTag.label"></v-text-field>
                <v-select
                  :items="structures"
                  item-text="title"
                  item-value="id"
                  multiple
                  v-model="selectedStructures"
                  return-object
                ></v-select>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary darken-1" text @click="close">
                Cancel
              </v-btn>
              <v-btn color="primary darken-1" text @click="save">
                Save
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="headline"
              >Sicuro di cancellare questo Tag?</v-card-title
            >
            <v-card-subtitle class="text-error-500"
              >L'operazione è irreversibile</v-card-subtitle
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >Annulla</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >OK</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon small class="mr-2" @click="editItem(item)">
        mdi-pencil
      </v-icon>
      <v-icon small @click="deleteItem(item)">
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:item.structures="{ item }">
      <ul>
        <li v-for="(st, s) in item.structures.items" :key="s">
          {{ st || '0' }}
        </li>
      </ul>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize">
        Reset
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TagList',
  data: () => ({
    selectedStructures: [],
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        text: 'Tag',
        align: 'start',
        sortable: false,
        value: 'label'
      },
      {
        text: 'Strutture',
        align: 'start',
        sortable: false,
        value: 'structures'
      },
      { text: 'Actions', value: 'actions', sortable: false }
    ],
    tags: [],
    editedIndex: -1,
    editedTag: {
      id: '',
      label: '',
      structures: []
    },
    defaultItem: {
      name: '',
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0
    }
  }),

  computed: {
    ...mapGetters('tags', ['getData']),
    ...mapGetters({ structures: 'structures/getData' }),
    formTitle() {
      return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    }
  },

  created() {
    this.initialize()
  },

  methods: {
    async initialize() {
      await this.$store.dispatch('tags/list')
      await this.$store.dispatch('structures/list')
    },

    editItem(item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedTag = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.tags.indexOf(item)
      this.editedTag = Object.assign({}, item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      this.tags.splice(this.editedIndex, 1)
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedTag = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedTag = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    save() {
      console.log(
        'Salvo i le strutture',
        this.editedTag,
        this.selectedStructures
      )

      // if (this.editedIndex > -1) {
      //   Object.assign(this.tags[this.editedIndex], this.editedTag)
      // } else {
      //   this.tags.push(this.editedTag)
      // }
      this.close()
    }
  }
}
</script>

<style></style>
